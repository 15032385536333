import { template as template_627de8500dd845f7b0d12fbd4059a1de } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_627de8500dd845f7b0d12fbd4059a1de(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
