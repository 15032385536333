import { template as template_bc3e3f8951474a68a743f9ed6c19d4e0 } from "@ember/template-compiler";
const FKLabel = template_bc3e3f8951474a68a743f9ed6c19d4e0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
