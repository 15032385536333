import { template as template_360670a324134d4ab4b1b895b17ea012 } from "@ember/template-compiler";
const FKText = template_360670a324134d4ab4b1b895b17ea012(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
