import { template as template_133d763e9fd448f98ae1e29d20994b78 } from "@ember/template-compiler";
const FKControlMenuContainer = template_133d763e9fd448f98ae1e29d20994b78(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
