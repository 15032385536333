import { template as template_5368044cef6841fb9f7e8a2f93ada32e } from "@ember/template-compiler";
const WelcomeHeader = template_5368044cef6841fb9f7e8a2f93ada32e(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
